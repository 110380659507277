import { EditorReadyFn, EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { Candidates } from '@wix/ambassador-members-v1-member-privacy-settings/types';

import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID, SANTA_MEMBERS_APP_ID } from '../../constants';
import { interactionEnded, interactionFailed, interactionStarted } from '../../../utils/monitoring';
import { startSequentialPromises, stopSequentialPromises } from '../../enforceSequentiality';
import { registerToComponentAddedToStageEvent } from '../../wrappers/components';
import { registerAlwaysAvailableApps } from './integration';
import { addLoginMenus, addMembersSubMenu } from './services/menus';
import { addMyAccountMenuItemToMembersAreaMenus } from './services/menu-items';
import { getMemberPrivacySettings } from '../../services/members-privacy-settings';
import { PageTitleType } from '../types';
import { setMembersAreaPageTitle } from './services/members-area-page';

const addMembersAreaPage = (editorSDK: EditorSDK) => {
  return editorSDK.document.application.add(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
    managingAppDefId: SANTA_MEMBERS_APP_ID,
    shouldNavigate: true,
    isSilent: true,
  });
};

const maybeChangeMembersAreaPageTitle = async (editorSDK: EditorSDK) => {
  try {
    const privacySettings = await getMemberPrivacySettings(editorSDK);
    const publicMemberCandidates = privacySettings?.publicMemberCandidates ?? Candidates.UNKNOWN;
    const revision = +(privacySettings?.revision ?? 0);

    if (revision > 0 && publicMemberCandidates !== Candidates.UNKNOWN) {
      const pageTitleType =
        publicMemberCandidates === Candidates.ANYONE ? PageTitleType.Social : PageTitleType.NonSocial;
      await setMembersAreaPageTitle(editorSDK, pageTitleType);
    }
  } catch {}
};

const maybeInstallMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  if (options.firstInstall) {
    await addMembersSubMenu(editorSDK);
    await addLoginMenus(editorSDK, options);

    await addMembersAreaPage(editorSDK);
    await addMyAccountMenuItemToMembersAreaMenus(editorSDK, options);
  } else {
    maybeChangeMembersAreaPageTitle(editorSDK);
  }
};

export const editorReady: EditorReadyFn = async (editorSDK, _, options) => {
  try {
    interactionStarted('editorReady');
    await maybeInstallMembersArea(editorSDK, options);
    await registerToComponentAddedToStageEvent(editorSDK);
    await registerAlwaysAvailableApps(editorSDK);
    startSequentialPromises();
    interactionEnded('editorReady');
  } catch (error: any) {
    interactionFailed('editorReady', error);
    console.error('Members Area installation failed');
    console.error(error);
    stopSequentialPromises(error);
    throw error;
  }
};
