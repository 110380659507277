import { EditorReadyFn, EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';

import { interactionEnded, interactionFailed, interactionStarted, log, toMonitored } from '../../../utils/monitoring';
import { withTimeoutMonitor } from '../../../utils/promise-timeout';
import {
  shouldDisableParallelAppInstall,
  shouldEnableSignUpPrivacyNoteType,
  shouldNotAllowStartAfterStop,
} from '../../../utils/experiments';
import { createBIService } from '../../../utils/bi';
import { APP_TOKEN } from '../../constants';
import { startSequentialPromises, stopSequentialPromises } from '../../enforceSequentiality';
import * as applicationState from '../../applicationState';
import * as membersLogic from '../../membersLogic';
import {
  maybeFixBrokenMenuItems,
  maybeFixLoginBarResponsiveLayout,
  maybeSetManagingAppDefIdForMAPages,
  removeBrokenInstallation,
  verifyMyAccountPage,
  verifyNoMissingLoginInADI,
} from '../../data-fixers';
import * as pagesService from '../../services/pages';
import { registerAlwaysAvailableApps } from '../../services/integration';
import * as componentsWrapper from '../../wrappers/components';

const INSTALLATION_TIMEOUT_AMOUNT = 15000;

async function maybeInstallMembersArea(editorSDK: EditorSDK, options: EditorReadyOptions) {
  if (!(await membersLogic.shouldInstall(editorSDK, options.firstInstall))) {
    return;
  }

  try {
    const interactionName = (await shouldDisableParallelAppInstall()) ? 'installSync' : 'install';
    const monitoredPromise = () => toMonitored(interactionName, () => membersLogic.install(editorSDK, options));
    await withTimeoutMonitor('installation', INSTALLATION_TIMEOUT_AMOUNT, monitoredPromise);
  } catch (e) {
    log('Removing initial installation as it failed', { extra: { error: (e as Error).toString() } });
    await removeBrokenInstallation(editorSDK, true, true);
    throw e;
  }
}

async function maybeSetSignUpPrivacyNoteType(editorSDK: EditorSDK, { firstInstall }: EditorReadyOptions) {
  try {
    if (firstInstall && (await shouldEnableSignUpPrivacyNoteType())) {
      editorSDK.siteMembers.setPrivacyNoteType(APP_TOKEN, 'NOTE');
    }
  } catch {
    log('Failed to set privacyNoteType');
  }
}

export const editorReady: EditorReadyFn = async (editorSDK, _appToken, options) => {
  const biService = await createBIService({ editorSDK, options });

  try {
    interactionStarted('editorReady');

    const editorReadyTransaction = async () => {
      await maybeSetManagingAppDefIdForMAPages({ editorSDK, options });

      await pagesService.setStateForPages(editorSDK);

      // MA-84 investigation, making sure My Account page is always there as it has to be
      await verifyMyAccountPage(options, editorSDK);

      // Install MA and delete it if anything goes wrong
      await maybeInstallMembersArea(editorSDK, options);

      // Try to solve some issues like duplicated menu items and etc, where MA is corrupted but doesn't have to be deleted
      await maybeFixBrokenMenuItems(editorSDK);

      // Try to fix login bar responsive layout on editorX
      await maybeFixLoginBarResponsiveLayout(editorSDK);

      // Set privacyNoteType on first install
      await maybeSetSignUpPrivacyNoteType(editorSDK, options);

      const successfullyInstalled = await applicationState.isApplicationReady(editorSDK);
      const noStartAfterStop = await shouldNotAllowStartAfterStop();
      if (successfullyInstalled) {
        // OB-19052 fixer - adds login bar when missing in ADI
        await verifyNoMissingLoginInADI(options.firstInstall, editorSDK);
        if (noStartAfterStop) {
          await registerAlwaysAvailableApps(editorSDK);
          await componentsWrapper.registerToComponentAddedToStageEvent(editorSDK);
          startSequentialPromises();
        }
      } else {
        stopSequentialPromises('Members Area was not installed.');
      }

      if (!noStartAfterStop) {
        await registerAlwaysAvailableApps(editorSDK);
        await componentsWrapper.registerToComponentAddedToStageEvent(editorSDK);
        startSequentialPromises();
      }
    };

    await editorReadyTransaction();

    interactionEnded('editorReady');
  } catch (e) {
    const errorMessage = typeof e === 'string' ? e : (e as Error).message;
    biService.logInstallationFailure(errorMessage);
    interactionFailed('editorReady', e as Error);
    console.error('Members Area installation failed');
    console.error(e);
    stopSequentialPromises(e);
    throw e;
  }
};
